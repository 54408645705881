import axios from "axios";
import { Post } from '@/types/post'
import { PostComment } from "@/types/post_comment";

export class PostRepository {
  private static readonly DOMAIN = process.env.VUE_APP_DOMAIN;
  private url: string;
  constructor(private projectId: number) {
    this.url = PostRepository.DOMAIN + "v1/projects/" + this.projectId + "/posts/";
  }

  async getList(params: { [key: string]: any }): Promise<Post[]> {
    return axios.get(this.url, { params: params }).then((response) => {
      return response.data.posts;
    });
  }
  async get(post_id: number): Promise<Post> {
    return axios.get(this.url + post_id).then((response) => {
      return response.data;
    });
  }
  async update(post_id: number, body: string): Promise<Post> {
    return axios
      .put(this.url + post_id, {
        post: { body: body }
      }).then((response) => {
        return response.data
      })
  }
  async add(body: string): Promise<Post> {
    return axios.post(this.url, {
      post: {
        body: body,
      },
    }).then((response) => {
      return response.data;

    })
  }
  async delete(post_id: number): Promise<void> {
    return axios.delete(this.url + post_id)

  }
  async iine(post_id: number): Promise<{ iine_count: number, iine_users: { user_name: string }[] }> {
    return axios.post(this.url + post_id + "/iine").then((response) => {
      return response.data
    })
  }
  async cancelIine(post_id: number): Promise<{ iine_count: number, iine_users: { user_name: string }[] }> {
    return axios.post(this.url + post_id + "/cancel_iine").then((response) => {
      return response.data
    })
  }
  async solve(post_id: number, solved_flg: boolean): Promise<boolean> {
    return axios.post(this.url + post_id + "/solve", { solved_flg: solved_flg }).then((response) => {
      return response.data.solved_flg
    })
  }
  async sendComment(post_id: number, body: string): Promise<PostComment> {
    return axios.post(this.url + post_id + "/comment",
      { post_comment: { body: body }, project_id: this.projectId }).then((response) => {
        return response.data
      })
  }
  async updateComment(post_id: number, comment_id: number, body: string): Promise<PostComment> {
    return axios.put(this.url + post_id + "/update_comment", { post_comment_id: comment_id, post_comment: { body: body } }).then((response) => {
      return response.data
    })
  }
  async deleteComment(post_id: number, comment_id: number): Promise<void> {
    return axios.delete(this.url + post_id + "/destroy_comment", { params: { post_comment_id: comment_id } })
  }
  async commentIine(post_id: number, comment_id: number): Promise<number> {
    return axios.post(this.url + post_id + "/comment_iine", { post_comment_id: comment_id }).then((response) => {
      return response.data.iine_count
    })
  }
  async commentCancelIine(post_id: number, comment_id: number): Promise<number> {
    return axios.post(this.url + post_id + "/comment_cancel_iine", { post_comment_id: comment_id }).then((response) => {
      return response.data.iine_count
    })
  }
}
