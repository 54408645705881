import { ref, computed, watch } from "vue";
import { useRoute } from 'vue-router'
import { Post } from "@/types/post"
import { PostRepository } from '@/repositories/PostRepository'
import { useExceptionMessage } from '@/logic/ExceptionMessageLogic'
export const PostsLogic = () => {
    const route = useRoute();
    const projectId = computed(() => Number(route.params.project_id))

    const postLoading = ref(false)
    const filterUnread = ref(false)
    const noMore = ref(false)
    const posts = ref<Post[]>([])
    const loadPostDisable = computed(() => postLoading.value || noMore.value);
    // 全部入りは一緒にしたいよなぁ・・・
    const loadPosts = async (clear = false) => {
        postLoading.value = true;
        if (clear) {
            posts.value = [];
            noMore.value = false;
        }
        const repository: PostRepository = new PostRepository(projectId.value);
        try {
            const ret_posts: Post[] = await repository.getList({ limit: 20, offset: posts.value.length, unread: filterUnread.value });
            if (ret_posts.length == 0) {
                noMore.value = true
            }
            for (const p of ret_posts) {
                posts.value.push(p);
            }
            postLoading.value = false;
        } catch (e: any) {
            useExceptionMessage(e, "エラーが発生しました。サーバもしくは入力データにエラーがあります。<br>再読み込みしてお試しください");
        }

    };
    watch(() => route.params.project_id, () => {
        if (route.params.project_id) {
            posts.value = []
            noMore.value = false
            loadPosts();
        }
    })

    const postStateReturn = () => {
        return {
            loadPosts,
            postLoading,
            loadPostDisable,
            posts,
            filterUnread
        }
    }
    return {
        postStateReturn,
        ...postStateReturn()
    }
}



